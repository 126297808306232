import React from 'react';
import { Typography, Grid, Card, CardContent, Button, Chip } from '@mui/material';
import { styled } from '@mui/system';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import StarIcon from '@mui/icons-material/Star';

// Sample data for Projects with detailed sections and achievements
const sampleProjects = [
  {
    title: 'Recommendation & Personalization',
    year: '2022',
    teamSize: 'Building best in class engineering platforms',
    myRole: 'Engineering Director',
    keyAchievements: 'Expanded role to lead engineering of recommendation product. Oversees engineering team globally for both the products',
    recognitions: 'Known for visionary leadership & empowering people',
    technicalSkills: ['GCP', 'GraphQL API', 'Spring Boot', 'Java', 'Python', 'Kafka', 'Data Engineering', 'Big Query', 'Vertex AI', 'Cassandra', 'React'],
    link: '/projects/recommendation-product',
  },
  {
    title: 'Personalization Product',
    year: '2021',
    teamSize: 'Setting up team',
    myRole: 'Senior Engineering Manager',
    keyAchievements: 'Built and led an engineering team for Personalization product.',
    recognitions: 'Known for building great engineering teams',
    technicalSkills: ['GCP', 'GraphQL API', 'Spring Boot', 'Java', 'Python', 'Kafka', 'Data Engineering', 'Big Query', 'Vertex AI', 'Cassandra', 'Neo4j Graph Database', 'React', 'Redis'],
    link: '/projects/personalization-product',
  },
  {
    title: 'API Assessment & Strategy',
    year: '2020',
    teamSize: '12 Weeks Program',
    myRole: 'Specialist Master',
    keyAchievements: 'Assessed API strategy for Life insurance account through interviews and delivered a comprehensive technology roadmap.',
    recognitions: 'Known for Microservices, APIs & Cloud Strategy',
    technicalSkills: ['AWS', 'API Management Tools', 'API Strategy', 'API Governance'],
    link: '/projects/api-management-strategy-assessment',
  },
  {
    title: 'Products Documentation Portal',
    year: '2015-2020',
    teamSize: '30+ Members',
    myRole: 'Specialist Master',
    keyAchievements: 'Led the team in building a technology company’s product documentation portal.',
    recognitions: 'Known as influential SME in open source, drupal & Adobe practice',
    technicalSkills: ['Spring Boot', 'Kafka', 'Elastic Search', 'MongoDB', 'Drupal', 'Angular', "Redis"],
    link: '/projects/product-documentation-site',
  },
  {
    title: 'Property Insurance Support',
    year: '2014',
    teamSize: '10+ Members',
    myRole: 'Team Lead',
    keyAchievements: 'Led a team in maintaining a property insurance company’s systems.',
    recognitions: 'Known for building inspiring team culture',
    technicalSkills: ['Spring MVC', 'Apache Tomcat'],
    link: '/projects/property-insurance-support',
  },
  {
    title: 'Auto Insurance Assessment and Redesign',
    year: '2012-2013',
    teamSize: '20+ Members',
    myRole: 'Team Lead',
    keyAchievements: 'Led a team in assessing and redesigning products for a major auto insurance company through APIs.',
    recognitions: 'Known as certified technology architect',
    technicalSkills: ['Spring', 'Spring MVC', "Apache CXF", "Apache Camel", "Apache Tomcat", 'PostGreSQL'],
    link: '/projects/auto-insurance-assessment-redesign',
  },
  {
    title: 'Health Insurance Portal',
    year: '2007-2011',
    teamSize: 'Onsite for 2 years',
    myRole: 'Individual Contributor',
    keyAchievements: 'Build health insurance portal while being onsite in the US from 2009 to 2012.',
    recognitions: 'Known for being excellent engineer & go getter attitude',
    technicalSkills: ['Spring', 'IBM Websphere Portal', 'MSSQL'],
    link: '/projects/health-insurance-leadership',
  },
  {
    title: 'Account Module Development',
    year: '2006-2007',
    teamSize: 'Small Team',
    myRole: 'Trainee Software Engineer',
    keyAchievements: 'Worked on the development of an account module.',
    recognitions: 'Known for strong engineering fundamentals',
    technicalSkills: ['JSP', 'Servlets', 'MySQL'],
    link: '/projects/account-module-development',
  },
];

// Define your color palette
const darkBlue = '#1976d2'; // Dark blue for text and accents
const lightGrey = '#f9f9f9'; // Light grey for backgrounds
const white = '#ffffff'; // White for text on dark backgrounds

// Styled components
const Section = styled('div')({
  padding: '50px 20px',
  backgroundColor: lightGrey, // Light grey background for the section
});

const ProjectCard = styled(Card)({
  borderRadius: '12px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  backgroundColor: white, // White background for the card
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
  },
});

const ProjectContent = styled(CardContent)({
  padding: '20px',
  borderRadius: '12px',
  color: darkBlue,
  display: 'flex',
  flexDirection: 'column',
});

const TitleText = styled(Typography)({
  fontWeight: 'bold',
  color: darkBlue, // Dark blue for the title
  marginBottom: '10px',
});

const RoleTag = styled(Chip)({
  backgroundColor: darkBlue,
  color: white,
  fontWeight: 'bold',
  marginBottom: '15px',
});

const TeamSizeText = styled(Typography)({
  fontSize: '0.875rem',
  color: darkBlue,
  marginBottom: '15px',
});

const AchievementsText = styled(Typography)({
  fontSize: '0.875rem',
  color: darkBlue,
  marginBottom: '20px',
});

const RecognitionsTag = styled(Chip)({
  backgroundColor: '#ff9800',
  color: white,
  fontWeight: 'bold',
  marginTop: '20px',
});

const TechnicalSkillsContainer = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '10px',
  marginTop: '10px',
});

const SkillTag = styled(Chip)({
  backgroundColor: '#e0e0e0',
  color: darkBlue,
  fontWeight: 'bold',
});

const LeadershipMetricsContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  marginTop: '10px',
});

const LeadershipMetric = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '5px',
  color: darkBlue,
});

const SectionTitle = styled(Typography)({
  fontWeight: 'bold',
  color: darkBlue,
  marginTop: '15px',
  marginBottom: '5px',
});

const ButtonStyled = styled(Button)({
  color: white,
  background: darkBlue,
  '&:hover': {
    background: '#004ba0', // Darker shade for hover
  },
  marginTop: '20px',
});

const Projects = React.forwardRef((props, ref) => {
  return (
    <Section ref={ref}>
      <Typography variant="h4" gutterBottom align="center" marginBottom={4} color={darkBlue}>
        Projects
      </Typography>
      <Grid container spacing={4}>
        {sampleProjects.map((project, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <ProjectCard>
              <ProjectContent>
                <Typography variant="h6" gutterBottom align="center" color={darkBlue}>
                  <AccessTimeIcon fontSize="small" /> {project.year}
                </Typography>
                <TitleText variant="h6" gutterBottom align="center">
                  {project.title}
                </TitleText>
                <Typography variant="body2" align="center" color={darkBlue} marginBottom="10px">
                  {project.teamSize}
                </Typography>
                <RoleTag label={project.myRole} />
                <SectionTitle variant="body1">
                  Key Achievements
                </SectionTitle>
                <AchievementsText variant="body2">
                  {project.keyAchievements}
                </AchievementsText>
                <TechnicalSkillsContainer>
                  {project.technicalSkills.map((skill, idx) => (
                    <SkillTag key={idx} label={skill} />
                  ))}
                </TechnicalSkillsContainer>
                <RecognitionsTag label={project.recognitions} />
              </ProjectContent>
            </ProjectCard>
          </Grid>
        ))}
      </Grid>
    </Section>
  );
});

export default Projects;
