import React from 'react';
import { Typography, Paper, Grid, Box } from '@mui/material';
import { styled } from '@mui/system';
import { Star, People, TrendingUp, Code } from '@mui/icons-material';

// Define your color palette
const darkBlue = '#1976d2'; // Dark blue for text and accents
const white = '#ffffff'; // White for background and text
const textColor = '#333'; // Default text color

// Styled components
const Section = styled('div')({
  padding: '20px 20px 60px 20px', // Adjusted padding: reduced top padding to remove extra space
  backgroundColor: white,
  marginTop: '0px', // Removed top margin
});

const MetricCard = styled(Paper)({
  padding: '20px',
  textAlign: 'center',
  backgroundColor: white,
  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
  borderRadius: '10px',
  color: darkBlue,
  width: '220px',
});

const IconWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: '10px',
});

const Summary = React.forwardRef((props, ref) => {
  return (
    <Section ref={ref}>
      <Typography variant="body1" align="center" paragraph color={textColor} style={{ fontSize: '18px' }}>
        I am known for
      </Typography>
      <Grid container spacing={3} justifyContent="center" style={{ flexWrap: 'nowrap', overflowX: 'auto' }}>
        <Grid item>
          <MetricCard>
            <IconWrapper>
              <Star color="inherit" fontSize="large" />
            </IconWrapper>
            <Typography variant="h6">Engineering Vision</Typography>
            <Typography variant="body2">
              Setting the engineering vision.
            </Typography>
          </MetricCard>
        </Grid>
        <Grid item>
          <MetricCard>
            <IconWrapper>
              <People color="inherit" fontSize="large" />
            </IconWrapper>
            <Typography variant="h6">Empowering People</Typography>
            <Typography variant="body2">
              Engineers emboldened to take ownership and excel.
            </Typography>
          </MetricCard>
        </Grid>
        <Grid item>
          <MetricCard>
            <IconWrapper>
              <TrendingUp color="inherit" fontSize="large" />
            </IconWrapper>
            <Typography variant="h6">Engineering Excellence</Typography>
            <Typography variant="body2">
              Enabling the best in class engineering practices
            </Typography>
          </MetricCard>
        </Grid>
        <Grid item>
          <MetricCard>
            <IconWrapper>
              <Code color="inherit" fontSize="large" />
            </IconWrapper>
            <Typography variant="h6">Delivering Impact</Typography>
            <Typography variant="body2">
              Delivering outcomes that exceed expectations.
            </Typography>
          </MetricCard>
        </Grid>
        <Grid item>
          <MetricCard>
            <IconWrapper>
              <Code color="inherit" fontSize="large" />
            </IconWrapper>
            <Typography variant="h6">Product Vision</Typography>
            <Typography variant="body2">
              Building platforms with customers at the center.
            </Typography>
          </MetricCard>
        </Grid>
      </Grid>
    </Section>
  );
});

export default Summary;
