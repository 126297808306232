import React, { useState, useEffect } from 'react';
import { Typography, Container, Grid, Button, TextField, IconButton, Box } from '@mui/material';
import { styled } from '@mui/system';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import axios from 'axios';
import { syllable } from 'syllable';

const phoneticCategories = {
    "Short vowels": ['ɪ', 'e', 'æ', 'ʌ', 'ə', 'ʊ', 'ɒ'],
    "Long vowels": ['iː', 'ɑː', 'ɔː', 'ʒː', 'uː'],
    "Diphthongs": ['eɪ', 'aɪ', 'ɔɪ', 'aʊ', 'əʊ'],
    "Additional diphthongs": ['ɪə', 'eə', 'ʊə'],
    "Plosives": ['p', 'b', 't', 'd', 'k', 'g'],
    "Fricatives": ['f', 'v', 'θ', 'ð', 's', 'z'],
    "Additional fricatives": ['ʃ', 'ʒ', 'h'],
    "Affricates": ['tʃ', 'dʒ'],
    "Nasals": ['m', 'n', 'ŋ'],
    "Approximants": ['l', 'r', 'j', 'w']
};

const Section = styled('div')({
    padding: '20px',
    marginBottom: '20px',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#ffffff',
});

const WordItem = styled('div')({
    padding: '10px',
    margin: '10px 0',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
});

const PhoneticButton = styled(Button)({
    margin: '5px',
    minWidth: '40px',
    fontSize: '1.2rem',
    color: '#1976d2',
    backgroundColor: '#e0f7fa',
    '&:hover': {
        backgroundColor: '#b2ebf2',
    },
});

const StyledButton = styled(Button)({
    backgroundColor: '#1976d2',
    color: '#ffffff',
    '&:hover': {
        backgroundColor: '#115293',
    },
    marginTop: '10px',
});

const WordMeaning = () => {
    const [words, setWords] = useState([]);
    const [newWord, setNewWord] = useState({
        word: '',
        phonetic: '',
        meaning: '',
        examples: '',
        notes: '',
        synonyms: '',
        homophones: '',
        wordType: '',
        tags: [],
        syllables: [],  // Ensure this is initialized as an empty array
        dictionaryIPA: '' // Added to store the fetched IPA pronunciation
    });
    const [searchTerm, setSearchTerm] = useState('');
    const [editingWordId, setEditingWordId] = useState(null);

    useEffect(() => {
        fetchWords();
    }, []);

    const fetchWords = async () => {
        try {
            const response = await axios.get('https://vocabularytracker.s3.eu-north-1.amazonaws.com/words.json');
            setWords(response.data.words || []);
        } catch (error) {
            console.error('Error fetching words:', error);
        }
    };

    const handleChange = (e) => {
        setNewWord({ ...newWord, [e.target.name]: e.target.value });
    };

   

    const validatePhonetic = async (word, phonetic) => {
        const apiUrl = `https://api.dictionaryapi.dev/api/v2/entries/en/${word}`; // Replace with your API URL and key
    
        try {
            // Make an API call to fetch the IPA
            const response = await axios.get(apiUrl);
    
            if (response.data && response.data[0] && response.data[0].hwi) {
                const apiPhonetic = response.data[0].hwi.prs[0].ipa;
    
                // Compare the phonetic we have with the IPA from the API
                if (apiPhonetic && apiPhonetic === phonetic) {
                    return true; // Phonetic matches
                } else {
                    return false; // Phonetic does not match
                }
            }
        } catch (error) {
            console.error("Error fetching IPA:", error);
        }
    
        return false; // Default to false if something goes wrong
    };
    

    const handleAddWord = async () => {
        try {
            const updatedWords = editingWordId
                ? words.map(word => word.id === editingWordId ? { ...newWord, id: editingWordId } : word)
                : [...words, { ...newWord, id: Date.now() }];
            await axios.put('https://vocabularytracker.s3.eu-north-1.amazonaws.com/words.json', { words: updatedWords });
            setWords(updatedWords);
            resetForm();
        } catch (error) {
            console.error('Error adding/updating word:', error);
        }
    };

    const handleDeleteWord = async (id) => {
        try {
            const updatedWords = words.filter((word) => word.id !== id);
            await axios.put('https://vocabularytracker.s3.eu-north-1.amazonaws.com/words.json', { words: updatedWords });
            setWords(updatedWords);
        } catch (error) {
            console.error('Error deleting word:', error);
        }
    };

    const resetForm = () => {
        setNewWord({
            word: '',
            phonetic: '',
            meaning: '',
            examples: '',
            notes: '',
            synonyms: '',
            homophones: '',
            wordType: '',
            tags: [],
            syllables: [],
            dictionaryIPA: '' // Reset dictionary IPA as well
        });
        setEditingWordId(null);
    };

    const handleEditWord = (word) => {
        setNewWord(word);
        setEditingWordId(word.id);
    };

    const addPhoneticSymbol = (symbol) => {
        setNewWord(prev => ({ ...prev, phonetic: prev.phonetic + symbol }));
    };

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    const extractSyllables = (word) => {
        if (!word) return [];
    
        const syllablePattern = /[aeiouy]{1,2}[^aeiouy\s]{0,2}/gi;
        const syllables = word.match(syllablePattern) || []; // Find all matches based on the pattern
    
        return syllables.map((syllable, index) => `Syllable ${index + 1}: ${syllable}`);
    };

    const fetchDictionaryIPA = async (word) => {
        try {
            const response = await axios.get(`https://od-api-sandbox.oxforddictionaries.com/api/v2/${word}`, {
                headers: {
                    'app_id': '	8726273f', // Replace with your App ID
                    'app_key': 'f3bcefca81f7c6cdb45df146f23842e3' // Replace with your API Key
                }
            });
            const ipa = response.data.results[0].lexicalEntries[0].pronunciations[0].phoneticSpelling;
            setNewWord((prev) => ({ ...prev, dictionaryIPA: ipa }));
        } catch (error) {
            console.error('Error fetching IPA:', error);
        }
    };
    

    const handleWordBlur = () => {
        const syllables = extractSyllables(newWord.word);
        setNewWord((prev) => ({ ...prev, syllables: syllables }));
        fetchDictionaryIPA(newWord.word); // Fetch IPA when the word input field loses focus
    };

    const filteredWords = words.filter((word) => 
        word.word.toLowerCase().includes(searchTerm.toLowerCase()) || 
        word.tags.some(tag => tag.toLowerCase().includes(searchTerm.toLowerCase()))
    );

    return (
        <Container maxWidth="md" style={{ marginTop: '50px' }}>
            <Typography variant="h3" gutterBottom style={{ color: '#1976d2', textAlign: 'center' }}>
                Vocabulary Tracker
            </Typography>

            <TextField
                label="Search by word or tag"
                fullWidth
                variant="outlined"
                value={searchTerm}
                onChange={handleSearch}
                style={{ marginBottom: '20px' }}
            />

            <Section>
                <Typography variant="h5" style={{ marginBottom: '10px' }}>Add New Word</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Word"
                            name="word"
                            fullWidth
                            variant="outlined"
                            value={newWord.word}
                            onChange={handleChange}
                            onBlur={handleWordBlur}  // Add onBlur event
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Phonetic"
                            name="phonetic"
                            fullWidth
                            variant="outlined"
                            value={newWord.phonetic}
                            onChange={handleChange}
                        />
                        <Typography variant="subtitle1" style={{ marginTop: '10px' }}>Phonetic symbols (all 44 IPA symbols for English)</Typography>
                        {Object.entries(phoneticCategories).map(([category, symbols]) => (
                            <Box key={category} style={{ marginTop: '10px' }}>
                                <Typography variant="subtitle2" style={{ color: '#666' }}>{category}</Typography>
                                <Box display="flex" flexWrap="wrap">
                                    {symbols.map((symbol) => (
                                        <PhoneticButton
                                            key={symbol}
                                            onClick={() => addPhoneticSymbol(symbol)}
                                        >
                                            {symbol}
                                        </PhoneticButton>
                                    ))}
                                </Box>
                            </Box>
                        ))}
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Meaning"
                            name="meaning"
                            fullWidth
                            multiline
                            rows={2}
                            variant="outlined"
                            value={newWord.meaning}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Examples"
                            name="examples"
                            fullWidth
                            multiline
                            rows={2}
                            variant="outlined"
                            value={newWord.examples}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Notes"
                            name="notes"
                            fullWidth
                            multiline
                            rows={2}
                            variant="outlined"
                            value={newWord.notes}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Synonyms"
                            name="synonyms"
                            fullWidth
                            variant="outlined"
                            value={newWord.synonyms}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Homophones"
                            name="homophones"
                            fullWidth
                            variant="outlined"
                            value={newWord.homophones}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Word Type"
                            name="wordType"
                            fullWidth
                            variant="outlined"
                            value={newWord.wordType}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Tags (comma-separated)"
                            name="tags"
                            fullWidth
                            variant="outlined"
                            value={newWord.tags.join(', ')}
                            onChange={(e) => setNewWord({ ...newWord, tags: e.target.value.split(',').map(tag => tag.trim()) })}
                        />
                    </Grid>
                </Grid>

                <Typography variant="h6" style={{ marginTop: '20px' }}>
                    Syllables: {newWord.syllables.join(', ') || 'N/A'}
                </Typography>
                <Typography variant="h6" style={{ marginTop: '10px' }}>
                    IPA: {newWord.dictionaryIPA || 'N/A'}
                </Typography>

                <StyledButton onClick={handleAddWord}>
                    {editingWordId ? 'Update Word' : 'Add Word'}
                </StyledButton>
            </Section>

            {filteredWords.length > 0 && (
                <Section>
                    <Typography variant="h5" style={{ marginBottom: '10px' }}>Word List</Typography>
                    {filteredWords.map((word) => (
                        <WordItem key={word.id}>
                            <div>
                                <Typography variant="h6">{word.word}</Typography>
                                <Typography variant="body2">Syllables: {word.syllables.join(', ')}</Typography>
                                <Typography variant="body2">
                Phonetic: {word.phonetic} 
                {validatePhonetic(word.word, word.phonetic) ? (
                    <span style={{ color: 'green' }}> ✅</span>
                ) : (
                    <span style={{ color: 'red' }}> ❌</span>
                )}
            </Typography>
                                <Typography variant="body2">Dictionary Phonetic: {word.dictionaryIPA}</Typography>
                                <Typography variant="body2">Meaning: {word.meaning}</Typography>
                                <Typography variant="body2">Examples: {word.examples}</Typography>
                                <Typography variant="body2">Notes: {word.notes}</Typography>
                                <Typography variant="body2">Synonyms: {word.synonyms}</Typography>
                                <Typography variant="body2">Homophones: {word.homophones}</Typography>
                                <Typography variant="body2">Word Type: {word.wordType}</Typography>
                                <Typography variant="body2">Tags: {word.tags.join(', ')}</Typography>
                                

                            </div>
                            <div>
                                <IconButton onClick={() => handleEditWord(word)}>
                                    <EditIcon />
                                </IconButton>
                                <IconButton onClick={() => handleDeleteWord(word.id)}>
                                    <DeleteIcon />
                                </IconButton>
                            </div>
                        </WordItem>
                    ))}
                </Section>
            )}
        </Container>
    );
};

export default WordMeaning;
