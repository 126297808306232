import React from 'react';
import { Typography, Grid, Card, CardContent } from '@mui/material';
import { styled } from '@mui/system';

// Define your color palette
const darkBlue = '#1976d2'; // Dark blue for text and accents
const white = '#ffffff'; // White for text on dark backgrounds

// Styled components
const Section = styled('div')({
  padding: '70px 20px 50px',
  backgroundColor: white,
});

const AspectCard = styled(Card)({
  borderRadius: '12px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.05)',
  },
});

const CardContentStyled = styled(CardContent)({
  padding: '20px',
  backgroundColor: white,
  color: darkBlue,
  borderBottomLeftRadius: '12px',
  borderBottomRightRadius: '12px',
});

const TitleText = styled(Typography)({
  fontWeight: 'bold',
  color: darkBlue,
});

const StoryText = styled(Typography)({
  fontSize: '1rem',
  color: '#555',
  lineHeight: '1.8',
  marginBottom: '20px',
});

const ArticleDetail6 = () => {
  return (
    <Section>
      <Typography variant="h4" gutterBottom align="center" color={darkBlue}>
        The Hidden Battle Within
      </Typography>

      <Grid container spacing={4} justifyContent="center">
        <Grid item xs={12} md={10}>
          <AspectCard>
            <CardContentStyled>
              <TitleText variant="h6" gutterBottom>
                A Life-Changing Moment
              </TitleText>
              <StoryText paragraph>
                As a young boy, I was known for my fighting spirit—someone who loved a good challenge and, more importantly, loved to win. Whether it was a schoolyard scuffle or a competitive race, I had a reputation for being fiercely determined. However, a pivotal moment came when I was just in the 7th or 8th grade—one that would change not only my life but the way I understood my body and mind.
              </StoryText>
              <StoryText paragraph>
                I was climbing a tall tree, something I often did without a second thought, but that day was different. I lost my grip and fell, not just to the ground, but onto a sharp piece of wood that pierced my body, dangerously close to my most sensitive areas. It was excruciating, yet in that moment, I didn’t want to show weakness. I pulled the wood out, cleaned my clothes, and determined that my parents should never know.
              </StoryText>
              <StoryText paragraph>
                But when I got home, the pain intensified. It wasn’t long before I found myself in a hospital in Manali, Himachal Pradesh, facing a situation far more serious than I had ever anticipated. What followed was a grueling recovery process, days of hospitalization, and endless trips to the washroom as my digestive system refused to function properly. There were moments when my situation became life-threatening, but I was a fighter—I always had been. And then, miraculously, my body began to heal.
              </StoryText>
            </CardContentStyled>
          </AspectCard>
        </Grid>

        <Grid item xs={12} md={10}>
          <AspectCard>
            <CardContentStyled>
              <TitleText variant="h6" gutterBottom>
                The Struggles That Followed
              </TitleText>
              <StoryText paragraph>
                By the time I reached 10th grade, something new emerged: my body had become hypersensitive. I would experience uncontrollable shivers, especially when faced with stressful situations. These episodes stayed with me for years, ebbing and flowing but never fully going away. I didn’t understand what was happening, but I knew it was something beyond my control.
              </StoryText>
              <StoryText paragraph>
                In 2012, while I was living in the United States, I finally recognized the full extent of the issue—my body would blank out or shiver in moments of stress. These experiences weren’t just inconvenient; they were unsettling. For years, I lived with them, not fully understanding why they were happening.
              </StoryText>
            </CardContentStyled>
          </AspectCard>
        </Grid>

        <Grid item xs={12} md={10}>
          <AspectCard>
            <CardContentStyled>
              <TitleText variant="h6" gutterBottom>
                The Breakthrough in 2018
              </TitleText>
              <StoryText paragraph>
                It wasn’t until 2018 that everything changed. That year was transformative, not because my symptoms disappeared, but because I became self-aware. Through meditation and deep introspection, I learned to understand my body’s reactions, not just as random physical symptoms but as signals of deeper internal resistance. I realized that every body has its own speed of reacting to stress, and mine was telling me something important.
              </StoryText>
              <StoryText paragraph>
                The lesson was clear: when the body feels discomfort, it resists. That resistance builds up, manifesting in shivers, blankness, and other stress-induced symptoms. What I had been experiencing for so many years was nothing more than my body’s way of protecting itself from situations it deemed unsafe.
              </StoryText>
            </CardContentStyled>
          </AspectCard>
        </Grid>

        <Grid item xs={12} md={10}>
          <AspectCard>
            <CardContentStyled>
              <TitleText variant="h6" gutterBottom>
                A New Approach to Stress
              </TitleText>
              <StoryText paragraph>
                Through self-awareness, I learned that the key wasn’t to fight this resistance, as I had fought so many things before. The key was to let go. To not resist. To let the energy in my body flow freely, without the walls I had unknowingly built up around it.
              </StoryText>
              <StoryText paragraph>
                Stress, I discovered, isn’t an external force. It’s simply the result of energy flows resisting each other within us. The true power lies in becoming aware of those flows—understanding them, and allowing them to exist without trying to control or fight them. When I stopped resisting, my body stopped fighting itself.
              </StoryText>
            </CardContentStyled>
          </AspectCard>
        </Grid>

        <Grid item xs={12} md={10}>
          <AspectCard>
            <CardContentStyled>
              <TitleText variant="h6" gutterBottom>
                Lessons for Life and Leadership
              </TitleText>
              <StoryText paragraph>
                Today, as an engineering leader, this journey of self-awareness shapes the way I lead teams, solve problems, and approach challenges. I no longer push through stress with brute force. Instead, I guide myself and those around me to be more aware of our responses, both mental and physical, so that we can work in harmony with the energy around us.
              </StoryText>
              <StoryText paragraph>
                This story is not just mine. It’s a story of how, in the toughest moments of life, resilience and self-awareness become the greatest assets. Whether you’re leading a team, facing personal struggles, or simply dealing with the daily stresses of life, remember that the first step toward conquering anything is understanding yourself.
              </StoryText>
              <StoryText paragraph>
                Let your energy flow—don’t fight it. Because sometimes, the most powerful victories come not from fighting, but from letting go.
              </StoryText>
            </CardContentStyled>
          </AspectCard>
        </Grid>
      </Grid>
    </Section>
  );
};

export default ArticleDetail6;
