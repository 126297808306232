import React from 'react';
import { Typography, Grid, Card, CardContent, Box } from '@mui/material';
import { styled } from '@mui/system';

// Define color palette
const darkBlue = '#1976d2';
const lightBlue = '#5cacee';
const white = '#ffffff';
const gray = '#666';

// Styled Components
const Section = styled('div')({
  padding: '50px 20px',
  backgroundColor: white,
});

const RoleCard = styled(Card)({
  borderRadius: '12px',
  boxShadow: '0 8px 15px rgba(0, 0, 0, 0.15)',
  padding: '20px',
  backgroundColor: '#f5f5f5',
  color: gray,
});

const TitleText = styled(Typography)({
  fontWeight: 'bold',
  color: darkBlue,
  fontSize: '1.2rem',
});

const DescriptionText = styled(Typography)({
  fontSize: '1rem',
  color: gray,
});

const SectionTitle = styled(Typography)({
  fontWeight: 'bold',
  fontSize: '1.5rem',
  color: darkBlue,
  marginBottom: '20px',
  textAlign: 'center',
});

// Career Path UI Component
const CareerPathUI = () => {
  return (
    <Box>
      <SectionTitle>🚀 Engineering Career Path: Unlocking Your Future!</SectionTitle>
      <Grid container spacing={4}>
        {/* Product-Based Companies Column */}
        <Grid item xs={12} sm={4}>
          <SectionTitle>🌟 Global Capability Centers</SectionTitle>
          <Section>
            <RoleCard>
              <CardContent>
                <TitleText>Associate Software Engineer</TitleText>
                <DescriptionText>Begin your journey by translating user stories into code and supporting implementation under mentorship.</DescriptionText>
              </CardContent>
            </RoleCard>
            <RoleCard>
              <CardContent>
                <TitleText>Software Engineer</TitleText>
                <DescriptionText>Become a coding maestro! Write efficient code, ship features consistently, and tackle simple to medium design challenges.</DescriptionText>
              </CardContent>
            </RoleCard>
            <RoleCard>
              <CardContent>
                <TitleText>Senior Software Engineer</TitleText>
                <DescriptionText>Lead the charge on multiple features. Design complex solutions while delivering top-notch code that makes an impact.</DescriptionText>
              </CardContent>
            </RoleCard>
            <RoleCard>
              <CardContent>
                <TitleText>Lead Engineer</TitleText>
                <DescriptionText>Set the technical direction for your team. Drive innovation and lead with confidence in tackling intricate design problems.</DescriptionText>
              </CardContent>
            </RoleCard>
            <RoleCard>
              <CardContent>
                <TitleText>Engineering Manager</TitleText>
                <DescriptionText>Cultivate a thriving team and guide them towards success. Shape the technical vision and ensure high-quality deliverables.</DescriptionText>
              </CardContent>
            </RoleCard>
            <RoleCard>
              <CardContent>
                <TitleText>Senior Engineering Manager</TitleText>
                <DescriptionText>Manage multiple teams while building a robust technical strategy that aligns with business goals.</DescriptionText>
              </CardContent>
            </RoleCard>
            <RoleCard>
              <CardContent>
                <TitleText>Principal Engineer</TitleText>
                <DescriptionText>Expert in designing and implementing complex software solutions across an organization</DescriptionText>
              </CardContent>
            </RoleCard>
            <RoleCard>
              <CardContent>
                <TitleText>Engineering Director</TitleText>
                <DescriptionText>Craft a compelling tech vision that inspires one product or multiple products, influencing success across the organization.</DescriptionText>
              </CardContent>
            </RoleCard>
            <RoleCard>
              <CardContent>
                <TitleText>Senior Engineering Director</TitleText>
                <DescriptionText>Elevate your influence with a vision that spans multiple products, driving growth and innovation.</DescriptionText>
              </CardContent>
            </RoleCard>
            <RoleCard>
              <CardContent>
                <TitleText>Vice President</TitleText>
                <DescriptionText>Lead a portfolio with strategic insight and vision, steering the organization towards its goals.</DescriptionText>
              </CardContent>
            </RoleCard>
          </Section>
        </Grid>

        {/* Consulting Companies Column */}
        <Grid item xs={12} sm={4}>
          <SectionTitle>🧩 Consulting Companies</SectionTitle>
          <Section>
            <RoleCard>
              <CardContent>
                <TitleText>Business Technology Analyst</TitleText>
                <DescriptionText>Begin your journey by translating user stories into code and supporting implementation under mentorship.</DescriptionText>
              </CardContent>
            </RoleCard>
            <RoleCard>
              <CardContent>
                <TitleText>Consultant</TitleText>
                <DescriptionText>Deliver value through efficient coding and designing solutions to simple challenges, helping clients achieve their objectives.</DescriptionText>
              </CardContent>
            </RoleCard>
            <RoleCard>
              <CardContent>
                <TitleText>Specialist Senior</TitleText>
                <DescriptionText>A path towards shaping the technical direction of your team, guiding them in solving complex problems while ensuring high standards of performance.</DescriptionText>
              </CardContent>
            </RoleCard>
            <RoleCard>
              <CardContent>
                <TitleText>Senior Consultant</TitleText>
                <DescriptionText>Take the lead on projects! Design complex solutions and consistently deliver high-quality results that make a difference.</DescriptionText>
              </CardContent>
            </RoleCard>
            <RoleCard>
              <CardContent>
                <TitleText>Specialist Master</TitleText>
                <DescriptionText>Shape the technical direction of your team, guiding them in solving complex problems while ensuring high standards of performance.</DescriptionText>
              </CardContent>
            </RoleCard>
            <RoleCard>
              <CardContent>
                <TitleText>Manager</TitleText>
                <DescriptionText>Build successful teams tailored to client needs, ensuring that every project is a triumph.</DescriptionText>
              </CardContent>
            </RoleCard>
            <RoleCard>
              <CardContent>
                <TitleText>Senior Manager</TitleText>
                <DescriptionText>Oversee multiple teams and accounts, delivering exceptional results and enhancing client satisfaction.</DescriptionText>
              </CardContent>
            </RoleCard>
            <RoleCard>
              <CardContent>
                <TitleText>Managing Director</TitleText>
                <DescriptionText>Steer the strategic direction of your consulting firm, championing client success at every level.</DescriptionText>
              </CardContent>
            </RoleCard>
          </Section>
        </Grid>

        {/* Service-Based Companies Column */}
        <Grid item xs={12} sm={4}>
          <SectionTitle>💼 Service-Based Companies</SectionTitle>
          <Section>
            <RoleCard>
              <CardContent>
                <TitleText>Associate Software Engineer</TitleText>
                <DescriptionText>Begin your journey by translating user stories into code and supporting implementation under mentorship.</DescriptionText>
              </CardContent>
            </RoleCard>
            <RoleCard>
              <CardContent>
                <TitleText>Software Engineer</TitleText>
                <DescriptionText>Hone your skills in efficient coding and consistent delivery while tackling design challenges with confidence.</DescriptionText>
              </CardContent>
            </RoleCard>
            <RoleCard>
              <CardContent>
                <TitleText>Senior Software Engineer</TitleText>
                <DescriptionText>Own multiple features and design complex solutions, delivering high-quality code that drives results.</DescriptionText>
              </CardContent>
            </RoleCard>
            <RoleCard>
              <CardContent>
                <TitleText>Team Lead</TitleText>
                <DescriptionText>Build a successful team culture. Lead technical initiatives and empower your team to excel.</DescriptionText>
              </CardContent>
            </RoleCard>
            <RoleCard>
              <CardContent>
                <TitleText>Assistant Manager</TitleText>
                <DescriptionText>Demonstrate your ability to create a successful team for specific accounts, making a significant impact.</DescriptionText>
              </CardContent>
            </RoleCard>
            <RoleCard>
              <CardContent>
                <TitleText>Manager</TitleText>
                <DescriptionText>Cultivate high-performing teams focused on successful account delivery and client relationships.</DescriptionText>
              </CardContent>
            </RoleCard>
            <RoleCard>
              <CardContent>
                <TitleText>Senior Manager</TitleText>
                <DescriptionText>Drive success across multiple accounts, leading teams to achieve outstanding results.</DescriptionText>
              </CardContent>
            </RoleCard>
            <RoleCard>
              <CardContent>
                <TitleText>Associate Director</TitleText>
                <DescriptionText>Exhibit leadership in managing a portfolio, ensuring alignment with strategic objectives.</DescriptionText>
              </CardContent>
            </RoleCard>
            <RoleCard>
              <CardContent>
                <TitleText>Managing Director</TitleText>
                <DescriptionText>Lead a portfolio that drives organizational success, setting the vision for the future.</DescriptionText>
              </CardContent>
            </RoleCard>
          </Section>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CareerPathUI;
