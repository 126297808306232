import React from 'react';
import { Typography, Grid, Card, CardContent, Button } from '@mui/material';
import { styled } from '@mui/system';

// Define your color palette
const darkBlue = '#1976d2'; // Dark blue for text and accents
const white = '#ffffff'; // White for text on dark backgrounds

// Styled components
const Section = styled('div')({
  padding: '70px 20px 50px', // Added extra padding at the top
  backgroundColor: white, // Changed to white
});

const AspectCard = styled(Card)({
  borderRadius: '12px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.05)',
  },
});

const CardContentStyled = styled(CardContent)({
  padding: '20px',
  backgroundColor: white, // Ensure background is white for content
  color: darkBlue,
  borderBottomLeftRadius: '12px',
  borderBottomRightRadius: '12px',
});

const TitleText = styled(Typography)({
  fontWeight: 'bold',
  color: darkBlue, // Set color to darkBlue to align with theme
});

const DescriptionText = styled(Typography)({
  fontSize: '0.9rem',
  color: '#666',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 4, // Limits the number of lines for descriptions
  WebkitBoxOrient: 'vertical',
});

const ActionButton = styled(Button)({
  backgroundColor: darkBlue,
  color: white,
  '&:hover': {
    backgroundColor: '#115293',
  },
  marginTop: '10px',
});

const ArticleDetail4 = () => {
  return (
    <Section>
      <Typography variant="h4" gutterBottom align="center" marginBottom={4} color={darkBlue}>
        Engineering Quality Excellence
      </Typography>
      <Typography variant="body1" paragraph align="center" color="#555" marginBottom={4}>
        Engineering quality excellence is more than just a goal. It is second nature of engineer by heart. Sharing second nature's traits
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} md={4}>
          <AspectCard>
            <CardContentStyled>
              <TitleText variant="h6" gutterBottom>Second Nature</TitleText>
              <DescriptionText paragraph>
                Cultivates best engineering quality practices until they become second nature. Discovers the journey from routine to mastery.
              </DescriptionText>
              
            </CardContentStyled>
          </AspectCard>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <AspectCard>
            <CardContentStyled>
              <TitleText variant="h6" gutterBottom>Visualize</TitleText>
              <DescriptionText paragraph>
                Visualizes architectural designs, and makes it so simple for everyone to understand and onboard
              </DescriptionText>
              
            </CardContentStyled>
          </AspectCard>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <AspectCard>
            <CardContentStyled>
              <TitleText variant="h6" gutterBottom>Decision-Making</TitleText>
              <DescriptionText paragraph>
                Uncovers the rationale behind key architectural decisions. Understands the importance of documenting both choices and their justifications.
              </DescriptionText>
              
            </CardContentStyled>
          </AspectCard>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <AspectCard>
            <CardContentStyled>
              <TitleText variant="h6" gutterBottom>Iterative Approach</TitleText>
              <DescriptionText paragraph>
                Embraces the iterative process to evolve from MVP to a polished product. Explores how feedback and continuous refinement drive success.
              </DescriptionText>
              
            </CardContentStyled>
          </AspectCard>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <AspectCard>
            <CardContentStyled>
              <TitleText variant="h6" gutterBottom>Code craftsmanship</TitleText>
              <DescriptionText paragraph>
                Maintains high standards through code reviews, unit testing, automated testing, and integration practices. Learns how quality practices shape robust systems.
              </DescriptionText>
              
            </CardContentStyled>
          </AspectCard>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <AspectCard>
            <CardContentStyled>
              <TitleText variant="h6" gutterBottom>Anticipating the Load</TitleText>
              <DescriptionText paragraph>
                Prepares for tomorrow’s demands by testing and optimizing performance today. Delves into strategies for handling anticipated traffic.
              </DescriptionText>
              
            </CardContentStyled>
          </AspectCard>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <AspectCard>
            <CardContentStyled>
              <TitleText variant="h6" gutterBottom>Code Without Repetition</TitleText>
              <DescriptionText paragraph>
                Builds low-code and no-code culture to minimize redundancy. Discovers the power of efficiency through the DRY (Don't Repeat Yourself) principle.
              </DescriptionText>
              
            </CardContentStyled>
          </AspectCard>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <AspectCard>
            <CardContentStyled>
              <TitleText variant="h6" gutterBottom>Scaling Beyond Limits</TitleText>
              <DescriptionText paragraph>
                Ensures systems can scale to meet future demands, and stay reliable
              </DescriptionText>
              
            </CardContentStyled>
          </AspectCard>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <AspectCard>
            <CardContentStyled>
              <TitleText variant="h6" gutterBottom>Securing the Future</TitleText>
              <DescriptionText paragraph>
                Implements cutting-edge security practices to safeguard your systems. Explores the essentials of a trustworthy platform.
              </DescriptionText>
              
            </CardContentStyled>
          </AspectCard>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <AspectCard>
            <CardContentStyled>
              <TitleText variant="h6" gutterBottom>Future-Proofing Upgrades</TitleText>
              <DescriptionText paragraph>
                Stay ahead by regularly upgrading systems and processes. Understands the importance of remaining current with technological advancements.
              </DescriptionText>
              
            </CardContentStyled>
          </AspectCard>
        </Grid>
      </Grid>
    </Section>
  );
};

export default ArticleDetail4;
