import React from 'react';
import { Typography, Container, Box } from '@mui/material';
import { styled } from '@mui/system';
import Header from '../../../../Header/Header';

// Define your color palette
const white = '#ffffff'; // White for background
const darkBlue = '#1976d2'; // Dark blue for text and accents
const textColor = '#333'; // Default text color
const borderColor = '#e0e0e0'; // Light grey border color
const shadowColor = 'rgba(0, 0, 0, 0.1)'; // Soft shadow color

// Card colors
const cardColors = [
  '#FFDDC1', // Light peach
  '#FFABAB', // Light coral
  '#FFC3A0', // Light pink
  '#FF677D', // Light red
  '#D4A5A5', // Light brown
  '#C2C2F0', // Light lavender
  '#B9FBC0'  // Light mint
];

// Styled components
const PageContainer = styled(Container)({
  marginTop: '100px', // Ensure there's space below the header
  padding: '20px',
  backgroundColor: white, // White background
  color: textColor, // Default text color
  textAlign: 'center',
});

const ArticleTitle = styled(Typography)({
  fontWeight: 'bold',
  marginBottom: '40px', // Increased margin for more separation
  fontSize: '2.5rem',
  color: darkBlue, // Dark blue for the title
});

const IntroductionText = styled(Typography)({
  fontSize: '1.2rem',
  lineHeight: '1.6',
  color: textColor,
  marginBottom: '40px', // Increased margin for more separation
  textAlign: 'center',
});

const CardContainer = styled(Box)({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(320px, 1fr))', // Adjusted min-width for larger cards
  gap: '60px', // Increased gap between cards for more space
  justifyContent: 'center',
  marginBottom: '60px', // Increased bottom margin for more space
  padding: '0 20px',
});

const Card = styled(Box)(({ cardColor }) => ({
  width: '100%',
  padding: '20px',
  borderRadius: '15px', // Rounded corners for a modern look
  backgroundColor: cardColor, // Apply card-specific background color
  border: `1px solid ${borderColor}`, // Light grey border
  boxShadow: `0 4px 8px ${shadowColor}`, // Soft shadow for depth
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  '&:hover': {
    transform: 'scale(1.02)',
    boxShadow: `0 8px 16px ${shadowColor}`, // Enhanced shadow on hover
  },
}));

const CardLabel = styled(Typography)({
  color: darkBlue, // Dark blue for labels
  fontSize: '1.5rem',
  fontWeight: 'bold',
  marginBottom: '15px', // Increased margin for more separation
  textAlign: 'center',
});

const CardContent = styled(Typography)({
  color: textColor,
  fontSize: '1rem',
  textAlign: 'center',
  lineHeight: '1.6',
  margin: '0',
});


const ArticleDetail = React.forwardRef((props, ref) => {
  return (
    <>
      <Header />
      <PageContainer ref={ref} id="article-detail">
        <ArticleTitle>The 7Es Approach: My Take on Engineering Manager Leadership</ArticleTitle>
        <IntroductionText>
          When I reflect on my past experiences in managing teams, I've observed that the 7 Es play a crucial role in being successful engineering manager. There’s also a discernible order of priority.
        </IntroductionText>
        <CardContainer>
          {cardColors.map((color, index) => (
            <Card key={index} cardColor={color}>
              <CardLabel>
                {index === 0 && '🔍 EGO'}
                {index === 1 && '🛠️ ENABLE'}
                {index === 2 && '🚀 ENSURE'}
                {index === 3 && '📊 ENTERPRISE'}
                {index === 4 && '🔧 EMPOWER'}
                {index === 5 && '🏆 EXEMPLARY'}
                {index === 6 && '🌱 EXPAND'}
              </CardLabel>
              <CardContent>
                {index === 0 && 'Understanding ego plays a crucial role in building the best team culture, where members feel valued, heard, empowered, and motivated to excel.'}
                {index === 1 && 'Enabling the team is key, revolving around aspects like culture, clarity, ownership, processes, tools, and frameworks. This ensures the development of a top-notch engineering platform and team culture, with a team hungry to push for continuous improvement.'}
                {index === 2 && 'Ensuring a seamless customer experience on the platform is paramount. This includes non-functional requirements like latency, volume, and feature quality. Cultivating a team culture where this becomes the ethos drives success.'}
                {index === 3 && 'Staying enterprise compliant ensures readiness to scale and meet the demands of a larger functionality scope.'}
                {index === 4 && 'Empowering the business and enabling them with the capabilities to configure and apply, freeing up engineering capacity for other crucial tasks and avoiding redundancy.'}
                {index === 5 && 'Delivering the best-in-class existing features ensures customers have a superior experience and shop with confidence.'}
                {index === 6 && 'Innovating, ideating, and building new features/components ensures customers continue to experience world-class innovation and exploration.'}
              </CardContent>
            </Card>
          ))}
        </CardContainer>
      </PageContainer>
    </>
  );
});

export default ArticleDetail;
